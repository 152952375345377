import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: left;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
  }

  span {
    color: var(--primary);
  }

  a {
    color: white;
  }

  p {
    line-height: 1.6em;
  }
`

const band_person = () => {
  return (
    <>
      <Seo title="Arkadiusz Malinowski" />
      <Layout>
        <SimpleBanner title="Arkadiusz Malinowski">
          <StaticImage className="banner__image desktop" src="../../images/band/arek1.jpg" />
          <StaticImage className="banner__image mobile" src="../../images/band/arek-mobile.jpg" />
        </SimpleBanner>
        <Container>
          <p>
            Wokalista, klawiszowiec, kompozytor, autor tekstów, w zespole pełni
            funkcję prowadzącego / wodzireja oraz dba o kwestie techniczne.
            Założyciel Memento Group, to właśnie z nim będziecie się najcześciej
            kontaktować - to on ustala z Wami szczegółowy scenariusz imprezy
            dbając o każdy szczegół. Jego zaangażowanie, energia i doświadczenie
            w branży sprawi, że Wasze przyjęcie będzie zorganizowane na
            najwyższym poziomie. Dodatkowo Arek współpracował ze znanymi
            kompozytorami i producentami. Jest laureatem wielu wojewódzkich
            konkursów oraz finalistą ogólnopolskiego Festiwalu Rock Star w 2012
            i 2014 roku. Jego twórczość mogliście usłyszeć wielokrotnie w
            stacjach radiowych jak np. Radio ESKA, RMF Maxxx oraz zobaczyć m.in.
            w Dzień Dobry TVN, a także podczas gościnnych występów z zespołem
            Volver m.in. na Festiwalu Top Trendy.
          </p>
        </Container>
      </Layout>
    </>
  )
}

export default band_person
